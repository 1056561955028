const message = {
  "button.options.record": "Enregistrer maintenant",
  "button.options.upload": "Envoyer des fichiers",

  "title.notFound": "Oups, il semble que cette page n'existe pas.",
  "title.eventNotFound": "Erreur lors de l'accès à l'événement",

  "subtitle.goBackTo": "Retour à la",
  "subtitle.eventNotFound": "Événement non trouvé ou expiré.",

  "message.termsOfUse":
    "En utilisant notre système, vous acceptez nos <code>Conditions d'utilisation</code>",
  "message.termsFirstParagraph":
    "En utilisant notre système de téléchargement de photos et de vidéos, vous acceptez, en tant qu'invité de l'événement, que nous collections et téléchargeons vos données, y compris des photos, des vidéos et des informations personnelles, et que nous les mettions à la disposition de l'organisateur de l'événement.",
  "message.termsSecondParagraph":
    "Nous n'accédons pas à vos fichiers sauf si le client le demande pour fournir une assistance technique, limitée à ce qui est nécessaire.",
  "message.termsThirdParagraph":
    "La société ne partage pas de données avec des tiers et maintient la confidentialité des informations, sauf lorsque la loi l'exige.",
  "message.options.record":
    "Enregistrez dès maintenant votre message spécial !",
  "message.options.upload":
    "Partagez les meilleures photos et vidéos de la fête !",

  // recorder
  "button.recorder.home": "Enregistrer le message",
  "button.recorder.understood": "Compris !",
  "button.recorder.close": "Fermer",
  "button.recorder.share": "Partager",
  "button.recorder.back": "Retour",

  "title.recorder.permissionsToRecordAudioVideo": "Permission d'enregistrer",
  "title.recorder.success": "Succès !",
  "title.recorder.uploading": "Envoi en cours",
  "title.recorder.alert": "Alerte !",
  "title.recorder.saving": "Enregistrement",
  "title.recorder.waiting": "En attente",
  "title.recorder.share": "Partagez !",
  "title.recorder.error": "Erreur !",
  "title.recorder.permissionCameraOrMicrophoneDenied":
    "Permission non accordée",
  "title.recorder.terms": "Conditions d'utilisation",

  "subtitle.recorder.nextScreenClickAllow":
    "Sur l'écran suivant, cliquez sur <code>Autoriser</code>.",
  "subtitle.recorder.allowReinforcement":
    "Vous devez cliquer sur <code>Autoriser</code> pour enregistrer votre message.",
  "subtitle.recorder.share": "Invitez vos amis à enregistrer aussi !",
  "subtitle.recorder.dontRefreshPage":
    "Veuillez ne pas actualiser ou fermer cette page.",
  "subtitle.recorder.status.success":
    "Votre message a été enregistré avec succès !",
  "subtitle.recorder.browserNotSupported":
    "Votre navigateur n'est pas pris en charge ou la permission n'a pas été accordée.",
  "subtitle.recorder.tryGrantPermission":
    "1. Actualisez la page et autorisez l'utilisation de la caméra.",
  "subtitle.recorder.tryAnotherBrowser":
    "2. Essayez d'utiliser un autre navigateur comme {chrome} ou {safari}.",
  "subtitle.recorder.mustLongerThan":
    "Le message doit durer plus de <code>5 secondes</code>.",
  "subtitle.recorder.offline":
    "Vous êtes hors ligne. Veuillez vérifier votre connexion.",
  "subtitle.recorder.startRecord": "Cliquez pour commencer à enregistrer.",
  "subtitle.recorder.recording": "Enregistrement...",
  "subtitle.recorder.uploadError": "Erreur lors du téléchargement de la vidéo.",
  "subtitle.recorder.tryAgain": "Vérifiez votre connexion et réessayez.",
  "subtitle.recorder.permissionCameraOrMicrophoneDenied":
    "L'utilisation de la caméra/du microphone n'a pas été autorisée.",
  "subtitle.recorder.trySafariBrowser":
    "1. Utilisez le navigateur <code>SAFARI</code>.",
  "subtitle.recorder.changeSiteSettingsUsingSafari":
    '2. Cliquez sur le bouton <code>"aA"</code> dans la barre d\'URL et sur <code>"Paramètres du site"</code>',
  "subtitle.recorder.grantCameraAndMicrophonePermissions":
    "3. <code>Autorisez l'utilisation</code> de la caméra et du microphone",
  "subtitle.recorder.refreshPage": "4. Actualisez la page",
  "subtitle.recorder.tryChromeBrowser":
    "1. Utilisez le navigateur <code>CHROME</code>",
  "subtitle.recorder.changeSiteSettingsUsingChrome":
    '2. Accédez aux paramètres du site et cliquez sur <code>"Permissions"</code>',

  "message.recorder.shareMessage":
    "Créez un souvenir spécial ! C'est rapide, facile et une excellente façon de montrer votre amour et votre soutien en ce jour. Cliquez sur le lien pour commencer à enregistrer maintenant !",
  "message.recorder.almostDone":
    "Presque fini ! Juste quelques secondes de plus.",
  "message.recorder.sendingMessage":
    "Nous envoyons maintenant votre message. Cela peut prendre un peu de temps car il est plein de choses formidables.",

  // uploader
  "button.uploader.home": "Envoyer des fichiers",
  "button.uploader.send_photos": "Envoyer photos",
  "button.uploader.send_video": "Envoyer vidéo",
  "button.uploader.close": "Fermer",
  "button.uploader.share": "Partager",
  "button.uploader.back": "Retour",
  "button.uploader.send": "Envoyer",
  "button.uploader.continue": "Continuer",
  "button.uploader.ok": "OK!",

  "title.uploader.success": "Succès!",
  "title.uploader.uploading": "Téléchargement",
  "title.uploader.alert": "Alerte!",
  "title.uploader.saving": "Sauvegarde",
  "title.uploader.waiting": "En attente",
  "title.uploader.share": "Partager!",
  "title.uploader.error": "Erreur!",
  "title.uploader.upload": "Télécharger",
  "title.uploader.shareMoments": "Partagez des moments",
  "title.uploader.uploadFailure": "Échec du téléchargement",
  "title.uploader.completed": "Terminé",
  "title.uploader.terms": "Conditions d'utilisation",

  "subtitle.uploader.filesWithError":
    "Fichiers qui <code>ne seront pas envoyés</code> :",
  "subtitle.uploader.uploadHistory": "Téléchargements terminés",
  "subtitle.uploader.filesReadyToUpload": "Fichiers prêts à être téléchargés :",

  "message.uploader.uploadMaxFilesLimit":
    "Envoyez jusqu'à <code>50 photos ou 1 vidéo</code> à la fois.",
  "message.uploader.uploadLimit": "Limite maximale :",
  "message.uploader.photoSizeLimit": "<code>20Mo</code> par photo",
  "message.uploader.videoSizeLimit": "<code>200Mo</code> par vidéo",
  "message.uploader.fileSizeError": "Le fichier dépasse la taille autorisée.",
  "message.uploader.uploading":
    "Veuillez ne pas actualiser ou fermer cette page.",
  "message.uploader.uploadError": "Échec du téléchargement.",
  "message.uploader.maxFilesLimitError":
    "Sélectionnez au <code>maximum 50 photos</code>.",
  "message.uploader.notFoundFilesToUpload":
    "<code>Aucun fichier ne peut être envoyé</code>.",
  "message.uploader.checkFileSizeAndTryAgain":
    "Veuillez vérifier la <code>taille du fichier</code> et réessayer.",
  "message.uploader.uploadComplete": "Téléchargement terminé",
  "message.uploader.allFilesUploaded": "Tous les fichiers ont été envoyés",
  "message.uploader.nFilesUploaded": "{n} fichiers envoyés",
  "message.uploader.nFilesNotUploaded": "{n} fichier(s) non envoyé(s)",
  "message.uploader.noFilesUploaded": "Aucun fichier n'a été envoyé",
  "message.uploader.checkYourConnectionAndTryAgain":
    "Vérifiez votre connexion et réessayez.",
  "message.uploader.uploadVerySlow":
    "Si le téléchargement est lent, sélectionnez quelques photos à la fois",
  "message.uploader.uploadTip":
    "Pour maintenir la qualité du fichier, téléchargez des fichiers depuis la galerie de votre appareil.",
  "message.uploader.shareMessage": "Partagez vos moments préférés de la fête !",
};

export default message;
