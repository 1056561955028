import { LogoIcon } from "assets/svg";
import LanguageButton from "components/theme/LanguageButton";
import ShareButton from "components/theme/ShareButton";
import { useLogEvent } from "hooks/useLogEvent";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useEventStore } from "store/useEventStore";
import { parseLanguage, selectLanguage, shareLink } from "utils/utils";
import recorderBlackIcon from "../assets/images/recorder-black-icon.png";
import recorderRedIcon from "../assets/images/recorder-red-icon.png";
import uploaderBlackIcon from "../assets/images/uploader-black-icon.png";
import uploaderRedIcon from "../assets/images/uploader-red-icon.png";

export default function Options() {
  const { event, language, setLanguage, theme, publicUrl } = useEventStore();
  const { logShareButton } = useLogEvent();
  const intl = useIntl();
  const navigate = useNavigate();

  const recordButtonIcon = useMemo(() => {
    return theme.name === "wedding-rose" ? recorderRedIcon : recorderBlackIcon;
  }, [theme]);

  const uploadButtonIcon = useMemo(() => {
    return theme.name === "wedding-rose" ? uploaderRedIcon : uploaderBlackIcon;
  }, [theme]);

  const handleRecordClick = () => navigate("/record/guide");

  const handleUploadClick = () => navigate("/upload");

  const onChangeLanguage = useCallback(() => {
    const nextLanguage = selectLanguage(event?.languages, language);
    setLanguage(nextLanguage as string);
  }, [language, setLanguage, event?.languages]);

  const languageLabel = useMemo(() => {
    return parseLanguage(event?.languages, language) as string;
  }, [event?.languages, language]);

  const onShareEventLink = useCallback(() => {
    const text = intl.formatMessage({
      id: "message.uploader.shareMessage",
      defaultMessage: "Compartilhe seus momentos favoritos da festa!",
    });
    shareLink(publicUrl, text, logShareButton);
  }, [intl, publicUrl, logShareButton]);

  const onLandingPageClick = useCallback(() => {
    const capsulaLadingPage = "https://galeria.capsuladotempo.com";
    window.open(capsulaLadingPage, "_blank");
  }, []);

  return (
    <div className="template-container px-10 py-16 flex flex-col items-center">
      <div
        className={`flex flex-col items-center py-5 px-10 mb-8 gap-2 card ${theme.card}`}
      >
        <img src={recordButtonIcon} alt="Gravar" className="w-32" />

        <p className="text-theme-black mt-3 w-48">
          <FormattedMessage
            id="message.options.record"
            defaultMessage="Grave agora sua mensagem especial!"
          />
        </p>

        <button
          type="button"
          className={`
              font-century-gothic-bold text-white py-3 w-[200px] flex whitespace-nowrap justify-center items-center rounded-xl ${theme.button}
            `}
          onClick={handleRecordClick}
        >
          <FormattedMessage
            id="button.options.record"
            defaultMessage="Gravar agora"
          />
        </button>
      </div>

      <div
        className={`flex flex-col items-center py-5 px-10 mb-8 gap-2 card ${theme.card}`}
      >
        <img src={uploadButtonIcon} alt="Upload" className="w-32" />

        <p className="text-theme-black mt-3 w-48">
          <FormattedMessage
            id="message.options.upload"
            defaultMessage="Compartilhe as melhores fotos e vídeos da festa!"
          />
        </p>

        <button
          type="button"
          className={`
              font-century-gothic-bold text-white py-3 w-[200px] flex whitespace-nowrap justify-center items-center rounded-xl ${theme.button}
            `}
          onClick={handleUploadClick}
        >
          <FormattedMessage
            id="button.options.upload"
            defaultMessage="Escolher arquivos"
          />
        </button>
      </div>

      <div className="w-full h-full pt-6 px-4 flex justify-start">
        <div>
          <LanguageButton label={languageLabel} onChange={onChangeLanguage} />
        </div>

        <div className="pl-2">
          <ShareButton onShare={onShareEventLink} />
        </div>

        <div className="pl-2">
          <button
            onClick={onLandingPageClick}
            type="button"
            className="shadow square-white bg-white text-theme-black p-3 h-10 w-10 flex justify-center items-center"
          >
            <LogoIcon width={24} height={24} outline="black" color="black" />
          </button>
        </div>
      </div>
    </div>
  );
}
