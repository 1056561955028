const message = {
  "button.options.record": "¡Graba ahora!",
  "button.options.upload": "Elige archivos",

  "title.notFound": "Vaya, parece que esta página no existe.",
  "title.eventNotFound": "Error al acceder al evento",

  "subtitle.goBackTo": "Volver a",
  "subtitle.eventNotFound": "Evento no encontrado o expirado.",

  "message.termsOfUse":
    "Al utilizar nuestro sistema, usted acepta nuestros <code>Términos de uso</code>",
  "message.termFirstParagraph":
    "Al utilizar nuestro sistema de carga de fotos y videos, usted, como invitado al evento, acepta que recopilemos y carguemos sus datos, incluidas fotos, videos e información personal, y los pongamos a disposición del anfitrión del evento.",
  "message.termSecondParagraph":
    "No accedemos a sus archivos a menos que el cliente lo solicite para brindar soporte técnico, limitado a lo necesario.",
  "message.termThirdParagraph":
    "La empresa no comparte datos con terceros y mantiene la confidencialidad de la información, excepto cuando lo exija la ley.",
  "message.options.record": "¡Graba tu mensaje especial ahora!",
  "message.options.upload":
    "¡Comparte las mejores fotos y videos de la fiesta!",

  // recorder
  "button.recorder.home": "Grabar mensaje",
  "button.recorder.understood": "¡Entendido!",
  "button.recorder.close": "Cerrar",
  "button.recorder.share": "Compartir",
  "button.recorder.back": "Regresar",

  "title.recorder.permissionsToRecordAudioVideo": "Permiso para grabar",
  "title.recorder.success": "¡Éxito!",
  "title.recorder.uploading": "Enviando",
  "title.recorder.alert": "¡Alerta!",
  "title.recorder.saving": "Guardando",
  "title.recorder.waiting": "Esperando",
  "title.recorder.share": "¡Comparte!",
  "title.recorder.error": "¡Error!",
  "title.recorder.permissionCameraOrMicrophoneDenied": "Permiso no concedido",
  "title.recorder.terms": "Términos de uso",

  "subtitle.recorder.nextScreenClickAllow":
    "En la siguiente pantalla haz clic en <code>Permitir</code>.",
  "subtitle.recorder.allowReinforcement":
    "Es necesario que hagas clic en <code>Permitir</code> para grabar tu mensaje.",
  "subtitle.recorder.share": "¡Invita a tus amigos a grabar también!",
  "subtitle.recorder.dontRefreshPage":
    "Por favor, no actualices ni cierres esta página.",
  "subtitle.recorder.status.success": "¡Tu mensaje ha sido grabado con éxito!",
  "subtitle.recorder.browserNotSupported":
    "Tu navegador no es compatible o no se concedió el permiso.",
  "subtitle.recorder.tryGrantPermission":
    "1. Actualiza la página y permite el uso de la cámara.",
  "subtitle.recorder.tryAnotherBrowser":
    "2. Intenta usar otro navegador como {chrome} o {safari}.",
  "subtitle.recorder.mustLongerThan":
    "El mensaje debe durar más de <code>5 segundos</code>.",
  "subtitle.recorder.offline":
    "Estás desconectado. Por favor, verifica tu conexión.",
  "subtitle.recorder.startRecord": "Haz clic para comenzar a grabar.",
  "subtitle.recorder.recording": "Grabando...",
  "subtitle.recorder.uploadError": "Error al subir el video.",
  "subtitle.recorder.tryAgain": "Verifica tu conexión e inténtalo de nuevo.",
  "subtitle.recorder.permissionCameraOrMicrophoneDenied":
    "No se concedió el permiso de cámara/micrófono.",
  "subtitle.recorder.trySafariBrowser":
    "1. Usa el navegador <code>SAFARI</code>",
  "subtitle.recorder.changeSiteSettingsUsingSafari":
    '2. Haz clic en el botón <code>"aA"</code> en la barra de URL y en <code>"Configuración del sitio"</code>',
  "subtitle.recorder.grantCameraAndMicrophonePermissions":
    "3. <code>Permite el uso</code> de la cámara y el micrófono",
  "subtitle.recorder.refreshPage": "4. Actualiza la página",
  "subtitle.recorder.tryChromeBrowser":
    "1. Usa el navegador <code>CHROME</code>",
  "subtitle.recorder.changeSiteSettingsUsingChrome":
    '2. Accede a la configuración del sitio y haz clic en <code>"Permisos"</code>',

  "message.recorder.shareMessage":
    "¡Crea un recuerdo especial! Es rápido, fácil y una gran manera de mostrar tu amor y apoyo en este día. ¡Haz clic en el enlace para empezar a grabar ahora!",
  "message.recorder.almostDone": "¡Casi terminado! Solo unos segundos más.",
  "message.recorder.sendingMessage":
    "Ahora estamos enviando tu mensaje. Puede tardar un poco porque está lleno de cosas geniales.",

  // uploader
  "button.uploader.home": "Enviar archivos",
  "button.uploader.send_photos": "Enviar fotos",
  "button.uploader.send_video": "Enviar video",
  "button.uploader.close": "Cerrar",
  "button.uploader.share": "Compartir",
  "button.uploader.back": "Regresar",
  "button.uploader.send": "Enviar",
  "button.uploader.continue": "Continuar",
  "button.uploader.ok": "OK!",

  "title.uploader.success": "¡Éxito!",
  "title.uploader.uploading": "Enviando",
  "title.uploader.alert": "¡Alerta!",
  "title.uploader.saving": "Guardando",
  "title.uploader.waiting": "Esperando",
  "title.uploader.share": "¡Comparte!",
  "title.uploader.error": "¡Error!",
  "title.uploader.upload": "Subir",
  "title.uploader.shareMoments": "Comparte momentos",
  "title.uploader.uploadFailure": "Error al subir",
  "title.uploader.completed": "Completado",
  "title.uploader.terms": "Términos de uso",

  "subtitle.uploader.filesWithError":
    "Archivos que <code>no se enviarán</code>:",
  "subtitle.uploader.uploadHistory": "Cargas completadas",
  "subtitle.uploader.filesReadyToUpload": "Archivos listos para subir:",

  "message.uploader.uploadMaxFilesLimit":
    "Envía hasta <code>50 fotos o 1 video</code> a la vez.",
  "message.uploader.uploadLimit": "Límite máximo:",
  "message.uploader.photoSizeLimit": "<code>20MB</code> por foto",
  "message.uploader.videoSizeLimit": "<code>200MB</code> por video",
  "message.uploader.fileSizeError": "Archivo excede tamaño permitido",
  "message.uploader.uploading":
    "Por favor, no actualices ni cierres esta página.",
  "message.uploader.uploadError": "Error al subir.",
  "message.uploader.maxFilesLimitError":
    "Selecciona un máximo de <code>50 fotos</code>.",
  "message.uploader.notFoundFilesToUpload":
    "<code></code>No hay archivos para subir.</code>.",
  "message.checkFileSizeAndTryAgain":
    "Por favor, verifica el <code>tamaño del archivo</code> e inténtalo de nuevo.",
  "message.uploader.uploadComplete": "Carga completada",
  "message.uploader.allFilesUploaded": "Todos los archivos se han subido",
  "message.uploader.nFilesUploaded": "{n} archivo(s) subido(s)",
  "message.uploader.nFilesNotUploaded": "{n} archivo(s) no subido(s)",
  "message.uploader.noFilesUploaded": "No se han subido archivos",
  "message.uploader.checkYourConnectionAndTryAgain":
    "Verifica tu conexión e inténtalo de nuevo.",
  "message.uploader.uploadVerySlow":
    "Si la carga es lenta, selecciona unas pocas fotos a la vez",
  "message.uploader.uploadTip":
    "Para mantener la calidad del archivo, sube archivos de la galería de tu dispositivo.",
  "message.uploader.shareMessage":
    "¡Comparte tus momentos favoritos de la fiesta!",
};

export default message;
